<template>
  <div>
    <div class="wrapper">
      <div class="activation__inner">
        <img src="@/assets/images/logo-dark.svg" alt="" />
        <span class="text">
          Оплата пройшла успішно!
        </span>
        <v-btn color="success" @click="$router.push('/')">
          Повернутися На Головну
        </v-btn>
        <span>
          З повагою NewSend
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isSuccess: false,
    user: {}
  })
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f8f8;
  position: relative;
  img {
    position: absolute;
    top: 110px;
    width: 200px;
  }
  .activation__inner {
    width: 800px;
    height: 614px;
    background: #fff;
    background-image: url("../assets/images/Frame.svg");
    background-size: contain;
    justify-content: center;
    padding: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .text {
      color: #24a148;
      font-size: 30px;
      font-weight: 700;
    }
  }
}
</style>
